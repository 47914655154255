import styled from "styled-components";

export const Spacer = styled.div<{ height?: number; width?: number }>`
  flex-grow: 0;
  flex-shrink: 0;
  ${(props) =>
    props.width
      ? `
          flex-basis: ${props.width}px;
          min-width: ${props.width}px;
          height: 0;
        `
      : null};
  ${(props) =>
    props.height
      ? `
          flex-basis: ${props.height}px;
          min-height: ${props.height}px;
          width: 0;
        `
      : null};
`;
