import React from "react";
import styled from "styled-components";
import { Column } from "../Styles";
import { Spacer } from "../utils/Spacer";
import Viewport from "../utils/Viewport";
import { SocialLinks } from "./NavBar/NavbarComponents";
import AwakenLogo from "../assets/awakenLogo.png";

function Footer() {
  const { isTabletAndAbove } = Viewport();
  return (
    <Column align="center">
      <Spacer height={48} />
      {isTabletAndAbove ? <Spacer height={100} /> : null}
      <LogoPlaceHolder src={AwakenLogo} />
      <Spacer height={40} />
      <SocialLinks />
      {isTabletAndAbove ? <Spacer height={124} /> : <Spacer height={92} />}
    </Column>
  );
}

const LogoPlaceHolder = styled.img`
  height: 100px;
  width: 100px;
`;

export default Footer;
