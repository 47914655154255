import { useState, useEffect } from "react";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function Viewport() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const checkIsMobile = () => windowDimensions.width < 768;
  const checkIsTablet = () =>
    windowDimensions.width < 1280 && window.innerWidth >= 768;
  const checkIsTabletAndAbvoe = () => windowDimensions.width >= 768;
  const checkIsDesktopAndAbove = () => windowDimensions.width >= 1280;

  const [isMobile, setIsMobile] = useState(checkIsMobile());
  const [isTablet, setIsTablet] = useState(checkIsTablet());
  const [isTabletAndAbove, setIsTabletAndAbove] = useState(
    checkIsTabletAndAbvoe()
  );
  const [isDesktopAndAbove, setIsDesktopAndAbove] = useState(
    checkIsDesktopAndAbove()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsMobile(checkIsMobile());
    setIsTablet(checkIsTablet());
    setIsTabletAndAbove(checkIsTabletAndAbvoe());
    setIsDesktopAndAbove(checkIsDesktopAndAbove());
  }, [windowDimensions]);

  return {
    isMobile: isMobile,
    isTablet: isTablet,
    isTabletAndAbove: isTabletAndAbove,
    isDesktopAndAbove: isDesktopAndAbove,
  };
}
