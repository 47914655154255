import { useRef, useState } from "react";
import { Element } from "react-scroll";
import styled, { css } from "styled-components";
import {
  Column,
  PrimaryTitle,
  Row,
  SecondaryTitle,
  StyledColumn,
} from "../../Styles";
import { Spacer } from "../../utils/Spacer";
import Viewport from "../../utils/Viewport";
import LaunchImg from "../../assets/01_launch.png";
import DAO from "../../assets/02_Awaken-DAO.png";
import MiniGame from "../../assets/03_Mini_Game.png";
import Phase2 from "../../assets/04_Phase2.png";
import LaunchImgBGI from "../../assets/roadmap_1.png";
import DAOBGI from "../../assets/roadmap_2.png";
import MiniGameBGI from "../../assets/roadmap_3.png";
import Phase2BGI from "../../assets/roadmap_4.png";

import { Fade, Grow, Typography } from "@material-ui/core";
import MenuCloseIcon from "../../assets/menuCloseIcon.svg";
import { Colors } from "../../utils/Colors";

const Roadmap: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  const [isOpened, setIsOpened] = useState(false);
  const [roadMapPage, setRoadMapPage] = useState(0);
  const [openFromOrigin, setOpenFromOrigin] = useState("0 0 0");

  const toggleOverlay = (id?: number) => {
    id !== undefined ? setRoadMapPage(id) : null;
    setIsOpened(!isOpened);
  };

  const textContent = [
    {
      number: "01",
      title: "Launch",
      content: (
        <ul>
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>Meet 3,000 Awakeners.</li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>Ensure a smooth minting and launching event</li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>List Awaken NFT on Topaz.</li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>
              Partner with other projects to spread the word of our vision, and
              build reputation.
            </li>
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            <li>Grow a strong Awaken community and team.</li>
          </ContentDescription>
        </ul>
      ),
      backgroundImg: LaunchImgBGI,
    },
    {
      number: "02",
      title: "AWAKEN-DAO",
      content: (
        <>
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            AWAKEN-DAO will be established after the initial launch of our
            collection, the DAO allows all committed community member to support
            each other, part of the project’s income will goes toward the DAO to
            support the operation.
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove} bold>
            “The bird that would soar above the level plain of tradition and
            prejudice must have strong wings.” - The Awakening
          </ContentDescription>
        </>
      ),
      backgroundImg: DAOBGI,
    },
    {
      number: "03",
      title: "Mini Game",
      content: (
        <>
          <ul>
            <ContentDescription isTabletAndAbove={isTabletAndAbove}>
              <li>Launch of Awaken mini games.</li>
            </ContentDescription>
            <br />
            <ContentDescription isTabletAndAbove={isTabletAndAbove}>
              <li>Only Awaken holders will be able to participate and earn.</li>
            </ContentDescription>
          </ul>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            Join Awaken Discord and follow Twitter for detail and future
            announcement.
          </ContentDescription>
        </>
      ),
      backgroundImg: MiniGameBGI,
    },
    {
      number: "04",
      title: "Phase 2",
      content: (
        <>
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            Community is everything, we need a strong community to achieve our
            mission in supporting more female artists & builders in this space.
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            In the future, in order for us to provide more benefit to our
            community, all AWAKEN holder will get exclusive whitelist spots in
            our upcoming project.
          </ContentDescription>
          <br />
          <ContentDescription isTabletAndAbove={isTabletAndAbove}>
            Here are some scheduled benefits Awaken holders can enjoy in the
            near future:
          </ContentDescription>
          <ul>
            <li>
              <ContentDescription isTabletAndAbove={isTabletAndAbove}>
                <li>
                  Exclusive whitelist spots for our upcoming sister project.
                </li>
              </ContentDescription>
            </li>
            <br />
            <li>
              <ContentDescription isTabletAndAbove={isTabletAndAbove}>
                <li>
                  Grants Program, provide grants & resources for eligible
                  projects and community member.
                </li>
              </ContentDescription>
            </li>
            <br />
            <li>
              <ContentDescription isTabletAndAbove={isTabletAndAbove}>
                <li>More will be announced in Discord and Twitter.</li>
              </ContentDescription>
            </li>
          </ul>
        </>
      ),
      backgroundImg: Phase2BGI,
    },
  ];

  const ref = useRef() as React.MutableRefObject<HTMLImageElement>;

  return (
    <Element name="roadmap">
      <StyledColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <PrimaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          ROAD MAP
        </PrimaryTitle>
        <SecondaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          Our Journey Has Begun
        </SecondaryTitle>
        {isDesktopAndAbove ? <Spacer height={80} /> : <Spacer height={36} />}
        <RoadmapRow justify="space-between" ref={ref}>
          <Fade
            in={!isOpened}
            timeout={{
              enter: 1300,
              exit: 500,
            }}
          >
            <Row>
              <RoadmapColumn align="space-between">
                <RoadmapItem
                  src={LaunchImg}
                  onClick={() => {
                    setOpenFromOrigin(`0 0 0`);
                    toggleOverlay(0);
                  }}
                />
                {isTabletAndAbove ? (
                  <Spacer width={29} />
                ) : (
                  <Spacer width={5} />
                )}
                <RoadmapItem
                  src={DAO}
                  onClick={() => {
                    setOpenFromOrigin(`0 ${ref.current.clientHeight}px`);
                    toggleOverlay(1);
                  }}
                />
              </RoadmapColumn>
              {isTabletAndAbove ? <Spacer width={29} /> : <Spacer width={5} />}
              <RoadmapColumn align="space-between">
                <RoadmapItem
                  src={MiniGame}
                  onClick={() => {
                    setOpenFromOrigin(`${ref.current.clientWidth}px 0`);
                    toggleOverlay(2);
                  }}
                />
                {isTabletAndAbove ? (
                  <Spacer width={29} />
                ) : (
                  <Spacer width={5} />
                )}
                <RoadmapItem
                  src={Phase2}
                  onClick={() => {
                    setOpenFromOrigin(
                      `${ref.current.clientWidth}px ${ref.current.clientHeight}px
                      `
                    );
                    toggleOverlay(3);
                  }}
                />
              </RoadmapColumn>
            </Row>
          </Fade>
          <Grow
            in={isOpened}
            style={{
              transformOrigin: openFromOrigin,
            }}
            timeout={{
              enter: 1300,
              exit: 700,
            }}
            unmountOnExit={true}
          >
            <Overlay
              backgroundImg={
                isTabletAndAbove
                  ? textContent[roadMapPage]?.backgroundImg
                  : undefined
              }
              padding={isTabletAndAbove ? "88px" : "24px"}
            >
              {isTabletAndAbove ? (
                <Row justify="flex-end">
                  <img
                    src={MenuCloseIcon}
                    alt="menu close icon"
                    onClick={() => {
                      toggleOverlay();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                </Row>
              ) : null}
              <Row width="100%" justify="space-between">
                <ContentNumber isTabletAndAbove={isTabletAndAbove}>
                  {textContent[roadMapPage]?.number}
                </ContentNumber>
                {!isTabletAndAbove ? (
                  <img
                    src={MenuCloseIcon}
                    alt="menu close icon"
                    onClick={() => {
                      toggleOverlay();
                    }}
                    style={{ cursor: "pointer", width: "24px" }}
                  />
                ) : null}
              </Row>
              <ContentTitle isTabletAndAbove={isTabletAndAbove}>
                {textContent[roadMapPage]?.title}
              </ContentTitle>
              {isTabletAndAbove ? (
                <Spacer height={16} />
              ) : (
                <Spacer height={8} />
              )}
              <DescriptionContainer
                style={{ maxWidth: "590px" }}
                isTabletAndAbove={isTabletAndAbove}
              >
                {textContent[roadMapPage]?.content}
              </DescriptionContainer>
            </Overlay>
          </Grow>
        </RoadmapRow>
        {isDesktopAndAbove ? <Spacer height={160} /> : <Spacer height={64} />}
      </StyledColumn>
    </Element>
  );
};

export default Roadmap;

const DescriptionContainer = styled(Column)<{ isTabletAndAbove: boolean }>`
  ${({ isTabletAndAbove }) =>
    !isTabletAndAbove
      ? css`
          overflow: scroll;
          padding-right: 13px;
        `
      : null}
`;

const Overlay = styled(Column)<{ backgroundImg?: string }>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(${({ backgroundImg }) => backgroundImg});
  background-size: cover;
  border-radius: 20px;
  background-color: ${Colors.WHITE};
`;

const RoadmapColumn = styled(Column)`
  flex-shrink: 1;
  max-width: 543px;
`;

const RoadmapRow = styled(Row)`
  max-width: 1115px;
  width: 100%;
  position: relative;
`;

const RoadmapItem = styled.img<{ width?: string; height?: string }>`
  height: ${({ height }) => (height ? height : undefined)};
  border-radius: 20px;
  width: 100%;
  cursor: pointer;
`;

const ContentNumber = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 900;
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "58px" : "24px")};
  color: black;
`;

const ContentTitle = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 700;
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "32px" : "16px")};
  color: black;
`;

const ContentDescription = styled(Typography)<{
  isTabletAndAbove: boolean;
  bold?: boolean;
}>`
  font-size: ${({ isTabletAndAbove }) => (isTabletAndAbove ? "20px" : "12px")};
  color: black;
  font-weight: ${({ bold }) => (bold ? "bold" : "undefined")};
`;
