import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    type: "dark",
  },

  typography: {
    fontFamily: [
      '"Helvetica Neue"',
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default theme