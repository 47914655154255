import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const Column = styled.div<{
  align?: string;
  justify?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align ? align : undefined)};
  justify-content: ${({ justify }) => (justify ? justify : undefined)};
  width: ${({ width }) => (width ? width : undefined)};
  height: ${({ height }) => (height ? height : undefined)};
  margin: ${({ margin }) => (margin ? margin : undefined)};
  padding: ${({ padding }) => (padding ? padding : undefined)};
`;

export const Row = styled.div<{
  align?: string;
  justify?: string;
  width?: string;
  height?: string;
  margin?: string;
  padding?: string;
}>`
  display: flex;
  flex-direction: row;:
  align-items: ${({ align }) => (align ? align : undefined)};
  justify-content: ${({ justify }) => (justify ? justify : undefined)};
  width: ${({ width }) => (width ? width : undefined)};
  height: ${({ height }) => (height ? height : undefined)};
  margin: ${({ margin }) => (margin ? margin : undefined)};
  padding: ${({ padding }) => (padding ? padding : undefined)};
`;

export const ResponsiveContainer = styled.div<{ isMobile: boolean }>`
  margin: ${(props) => (props.isMobile ? "0 32px" : "0 auto")};
`;

export const PrimaryTitle = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 900;
  font-size: ${(props) => (props.isTabletAndAbove ? "14px" : "10px")};
`;

export const SecondaryTitle = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 900;
  font-size: ${(props) => (props.isTabletAndAbove ? "36px" : "18px")};
`;

export const Text = styled(Typography)<{
  bold?: boolean;
  isTabletAndAbove: boolean;
}>`
  font-weight: ${(props) => (props.bold ? 700 : 500)};
  font-size: ${(props) => (props.isTabletAndAbove ? "20px" : "14px")};
`;

export const StyledColumn = styled(Column)<{
  isDesktopAndAbove?: boolean;
  isTabletAndAbove?: boolean;
}>`
  width: 100%;
  align-items: center;
  padding: ${(props) =>
    props.isDesktopAndAbove
      ? "0 210px"
      : props.isTabletAndAbove
      ? "0 64px"
      : "0 32px"};
`;
