import Viewport from "../../utils/Viewport";
import styled, { css } from "styled-components";
import {
  StyledColumn,
  PrimaryTitle,
  Row,
  SecondaryTitle,
  Text,
} from "../../Styles";
import { Spacer } from "../../utils/Spacer";
import AwakenGif from "../../assets/awakenGif.gif";
import { Element } from "react-scroll";

const Story: React.FC = () => {
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();
  return (
    <Element name="story">
      <StyledColumn
        isDesktopAndAbove={isDesktopAndAbove}
        isTabletAndAbove={isTabletAndAbove}
      >
        <PrimaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          STORY
        </PrimaryTitle>
        <SecondaryTitle align="center" isTabletAndAbove={isTabletAndAbove}>
          About Us
        </SecondaryTitle>
        {isTabletAndAbove ? <Spacer height={80} /> : <Spacer height={36} />}
        <Content isDesktopAndAbove={isDesktopAndAbove}>
          <Gif
            src={AwakenGif}
            isDesktopAndAbove={isDesktopAndAbove}
            alt="Awaken Art GIF"
          />
          <Spacer height={40} width={62} />
          <StoryContainer isDesktopAndAbove={isDesktopAndAbove}>
            <SecondaryTitle isTabletAndAbove={isTabletAndAbove}>
              Behind AWAKEN
            </SecondaryTitle>
            <Spacer height={32} />
            <Text isTabletAndAbove={isTabletAndAbove}>
              Awaken aims to reshape NFT value for holders through supporting
              females in NFT space, by bridging the gap with artists, grants
              program and community events.
            </Text>
            <Spacer height={24} />
            <Text isTabletAndAbove={isTabletAndAbove}>
              We are a community driven project, aiming to spread the tension of
              women at all levels, build value for Awaken holders and grow
              community to achieve something big. The space is long occupied by
              males. Female artists, builders, with great potential have been
              buried and ignored. We are here to support and uncover the inner
              strength of females in NFT spaces.
            </Text>
            <Spacer height={24} />
            <Text bold isTabletAndAbove={isTabletAndAbove}>
              Be Awaken & be Awaken.
            </Text>
            <Spacer height={24} />
            <Text bold isTabletAndAbove={isTabletAndAbove}>
              You Had The Power All Along, My Dear.
            </Text>
          </StoryContainer>
        </Content>
        {isDesktopAndAbove ? <Spacer height={110} /> : <Spacer height={64} />}
      </StyledColumn>
    </Element>
  );
};

const StoryContainer = styled.div<{ isDesktopAndAbove: boolean }>`
  width: ${(props) => (props.isDesktopAndAbove ? "505px" : "100%")};
  min-width: ${(props) => (props.isDesktopAndAbove ? "505px" : "unset")};
`;

const Gif = styled.img<{ isDesktopAndAbove: boolean }>`
  max-width: 489px;
  ${({ isDesktopAndAbove }) =>
    isDesktopAndAbove
      ? css`
          min-width: 328px;
        `
      : css`
          width: 100%;
        `}

  flex-shrink: 1;
  border-radius: 20px;
`;

const Content = styled(Row)<{ isDesktopAndAbove: boolean }>`
  justify-content: space-between;
  flex-direction: ${(props) => (props.isDesktopAndAbove ? "row" : "column")};
  align-items: center;
  max-width: 1115px;
  width: 100%;
`;

export default Story;
