import styled from "styled-components";
import Viewport from "../../utils/Viewport";
import React, { useEffect, useRef, useState } from "react";
import MenuIcon from "../../assets/menuIcon.svg";
import {
  DesktopNavBar,
  MobileNavBar,
  MobileNavDropdown,
} from "./NavbarComponents";

interface INavBarProps {
  setNavBarHeight: React.Dispatch<React.SetStateAction<number>>;
}

const NavBar: React.FC<INavBarProps> = (props) => {
  const { setNavBarHeight } = props;
  const { isDesktopAndAbove } = Viewport();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clientHeight, setClientHeight] = useState(0);

  const ref = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    setNavBarHeight(ref.current.clientHeight);
    setClientHeight(ref.current.clientHeight);
  }, []);

  return (
    <>
      <NavBarContainer isDesktopAndAbove={isDesktopAndAbove} ref={ref}>
        {isDesktopAndAbove ? (
          <DesktopNavBar />
        ) : (
          <MobileNavBar
            isOpen={isMenuOpen}
            onClick={() => {
              setIsMenuOpen(!isMenuOpen);
            }}
            src={MenuIcon}
          />
        )}
      </NavBarContainer>
      <MobileNavDropdown
        isOpen={isMenuOpen}
        navBarHeight={clientHeight}
        onClick={() => {
          setIsMenuOpen(!isMenuOpen);
        }}
      />
    </>
  );
};

export default NavBar;

const NavBarContainer = styled.div<{ isDesktopAndAbove: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => (props.isDesktopAndAbove ? "48px 160px" : "48px 32px")};
  z-index: 100;
  position: relative;
`;
