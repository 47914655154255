import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import DesktopHero from "../../assets/desktopHero.png";
import MobileHero from "../../assets/mobileHero.png";
import { Spacer } from "../../utils/Spacer";
import Viewport from "../../utils/Viewport";
import Roadmap from "./Roadmap";
import AboutUs from "./Story";
import FAQ from "./FAQ";

interface IHomePageProps {
  navBarOffset: number;
}

const HomePage: React.FC<IHomePageProps> = (props) => {
  const { navBarOffset } = props;
  const { isTabletAndAbove, isDesktopAndAbove } = Viewport();

  useEffect(() => {
    document.title = "Home - Awaken";
  }, []);

  const Hero = (): JSX.Element => (
    <>
      <HeroImage
        src={isTabletAndAbove ? DesktopHero : MobileHero}
        alt="Awaken hero image"
      />
      {isTabletAndAbove ? <Spacer height={150} /> : <Spacer height={40} />}
    </>
  );

  const Titles = (): JSX.Element => (
    <>
      <Title align="center" isTabletAndAbove={isTabletAndAbove}>
        “ Be Awaken & be Awaken ”
      </Title>
      {isDesktopAndAbove ? <Spacer height={150} /> : <Spacer height={16} />}
    </>
  );

  return (
    <HeroContainer offset={navBarOffset}>
      <Hero />
      <Titles />

      <AboutUs />
      <Roadmap />
      <FAQ />
      <Outlet />
    </HeroContainer>
  );
};

const HeroImage = styled.img`
  width: 100%;
`;

const HeroContainer = styled.div<{ offset: number }>`
  margin-top: -${(props) => props.offset}px;
`;

const Title = styled(Typography)<{ isTabletAndAbove: boolean }>`
  font-weight: 900;
  // font-size: ${(props) => (props.isTabletAndAbove ? "40px" : "24px")};
  font-size: 28px;
  text-transform: capitalize;
`;

export default HomePage;
